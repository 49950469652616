import Vue from 'vue'
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('login', require('./account/login').default);
Vue.component('register', require('./account/register').default);
Vue.component('forgot-password', require('./account/forgot-password').default);
Vue.component('reset-password', require('./account/reset-password').default);

Vue.component('charts-apex', require('./charts/apex').default);
Vue.component('charts-chartist', require('./charts/chartist').default);
Vue.component('charts-chartjs', require('./charts/chartjs').default);

Vue.component('form-elements', require('./forms/elements').default);
Vue.component('form-validation', require('./forms/validation').default);
Vue.component('form-advanced', require('./forms/advanced').default);
Vue.component('form-editor', require('./forms/ckeditor').default);
Vue.component('form-uploads', require('./forms/uploads').default);
Vue.component('form-repeater', require('./forms/repeater').default);
Vue.component('form-wizard', require('./forms/wizard').default);
Vue.component('form-mask', require('./forms/mask').default);

Vue.component('maps-google', require('./maps/google').default);

Vue.component('tables-basictable', require('./tables/basictable').default);
Vue.component('tables-advancedtable', require('./tables/advancedtable').default);

Vue.component('ui-alerts', require('./ui/alerts').default);
Vue.component('ui-buttons', require('./ui/buttons').default);
Vue.component('ui-cards', require('./ui/cards').default);
Vue.component('ui-carousel', require('./ui/carousel').default);
Vue.component('ui-dropdowns', require('./ui/dropdowns').default);
Vue.component('ui-grid', require('./ui/grid').default);
Vue.component('ui-images', require('./ui/images').default);
Vue.component('ui-modals', require('./ui/modals').default);
Vue.component('ui-rangeslider', require('./ui/rangeslider').default);
Vue.component('ui-progressbars', require('./ui/progressbars').default);
Vue.component('ui-sweet-alert', require('./ui/sweet-alert').default);
Vue.component('ui-tabs-accordions', require('./ui/tabs-accordions').default);
Vue.component('ui-typography', require('./ui/typography').default);
Vue.component('ui-video', require('./ui/video').default);
Vue.component('ui-general', require('./ui/general').default);
Vue.component('ui-colors', require('./ui/colors').default);

Vue.component('pages-404', require('./utility/404').default);
Vue.component('pages-500', require('./utility/500').default);
