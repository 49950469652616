import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        $isAuthed: false,
        $role: "user",
        $userName: "",
        $userId: "",
    },
    mutations: {
        updateIsAuthed: function(state, isAuthed){state.$isAuthed = isAuthed; },
        updateRole: function(state, role){state.$role = role; },
        updateUserName: function(state, userName){state.$userName = userName},
        updateUserId: function(state, userId){state.$userId = userId},
    },
    getters: {
        $isAuthed: state => state.$isAuthed,
        $role: state => state.$role,
        $userName: state=> state.$userName,
        $userId: state=> state.$userId,
    },
    plugins: [createPersistedState()],
});

export default store