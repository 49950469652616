<script>
    import simplebar from "simplebar-vue";

    /**
     * Nav-bar Component
     */
    export default {
        components: {simplebar},
        methods: {
            toggleMenu() {
                this.$parent.toggleMenu();
            },
            toggleRightSidebar() {
                this.$parent.toggleRightSidebar();
            },
            initFullScreen() {
                document.body.classList.toggle("fullscreen-enable");
                if (
                    !document.fullscreenElement &&
                    /* alternative standard method */ !document.mozFullScreenElement &&
                    !document.webkitFullscreenElement
                ) {
                    // current working methods
                    if (document.documentElement.requestFullscreen) {
                        document.documentElement.requestFullscreen();
                    } else if (document.documentElement.mozRequestFullScreen) {
                        document.documentElement.mozRequestFullScreen();
                    } else if (document.documentElement.webkitRequestFullscreen) {
                        document.documentElement.webkitRequestFullscreen(
                            Element.ALLOW_KEYBOARD_INPUT
                        );
                    }
                } else {
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }
                }
            },
            logout() {
                axios.get(this.apiURL + "/users/logout").then(response => {
                    window.location.href = "/login";
                });
            }
        }
    };
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/images/logo.svg" alt height="22"/>
            </span>
                        <span class="logo-lg">
              <img src="/images/logo-dark.png" alt height="17"/>
            </span>
                    </a>

                    <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="/images/stm_ava.png" alt height="25" rel="preload"/>
            </span>
                        <span class="logo-lg">
              <img src="/images/stm-logo.png" alt height="60" class="mt-3" rel="preload"/>
            </span>
                    </a>
                </div>
                <button
                        id="vertical-menu-btn"
                        type="button"
                        class="btn btn-sm px-3 font-size-16 header-item "
                        @click="toggleMenu"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>

            <div class="d-flex">
                <div class="header-menu">
                    <ul>
                        <li><a href="http://accounts.stylemix.net/" target="_blank">Accounts</a></li>
                        <li><a href="http://assets.stylemix.net/" target="_blank">Assets</a></li>
                        <li><a href="http://finance.stylemix.net/" target="_blank">Finance</a></li>
                        <li><a href="http://hr.stylemix.net/" target="_blank">HR</a></li>
                    </ul>
                </div>
            </div>

            <div class="d-flex">
                <b-dropdown
                        class="d-inline-block d-lg-none ml-2"
                        variant="black"
                        menu-class="dropdown-menu-lg p-0"
                        toggle-class="header-item noti-icon"
                        right
                >
                    <template v-slot:button-content>
                        <i class="mdi mdi-magnify"></i>
                    </template>

                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search ..."
                                        aria-label="Recipient's username"
                                />
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit">
                                        <i class="mdi mdi-magnify"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </b-dropdown>


                <!--<b-dropdown-->
                <!--right-->
                <!--menu-class="dropdown-menu-lg p-0"-->
                <!--toggle-class="header-item noti-icon"-->
                <!--variant="black"-->
                <!--&gt;-->
                <!--<template v-slot:button-content>-->
                <!--<i class="bx bx-bell bx-tada"></i>-->
                <!--<span class="badge badge-danger badge-pill">3</span>-->
                <!--</template>-->

                <!--<div class="p-3">-->
                <!--<div class="row align-items-center">-->
                <!--<div class="col">-->
                <!--<h6 class="m-0">Notifications</h6>-->
                <!--</div>-->
                <!--<div class="col-auto">-->
                <!--<a href="#!" class="small">View All</a>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<simplebar style="max-height: 230px;">-->
                <!--<a href class="text-reset notification-item">-->
                <!--<div class="media">-->
                <!--<div class="avatar-xs mr-3">-->
                <!--<span class="avatar-title bg-primary rounded-circle font-size-16">-->
                <!--<i class="bx bx-cart"></i>-->
                <!--</span>-->
                <!--</div>-->
                <!--<div class="media-body">-->
                <!--<h6 class="mt-0 mb-1">Your order is placed</h6>-->
                <!--<div class="font-size-12 text-muted">-->
                <!--<p class="mb-1">If several languages coalesce the grammar</p>-->
                <!--<p class="mb-0">-->
                <!--<i class="mdi mdi-clock-outline"></i> 3 min ago-->
                <!--</p>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</a>-->
                <!--<a href class="text-reset notification-item">-->
                <!--<div class="media">-->
                <!--<img-->
                <!--src="/images/users/avatar-3.jpg"-->
                <!--class="mr-3 rounded-circle avatar-xs"-->
                <!--alt="user-pic"-->
                <!--/>-->
                <!--<div class="media-body">-->
                <!--<h6 class="mt-0 mb-1">James Lemire</h6>-->
                <!--<div class="font-size-12 text-muted">-->
                <!--<p class="mb-1">It will seem like simplified English.</p>-->
                <!--<p class="mb-0">-->
                <!--<i class="mdi mdi-clock-outline"></i> 1 hours ago-->
                <!--</p>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</a>-->
                <!--<a href class="text-reset notification-item">-->
                <!--<div class="media">-->
                <!--<div class="avatar-xs mr-3">-->
                <!--<span class="avatar-title bg-success rounded-circle font-size-16">-->
                <!--<i class="bx bx-badge-check"></i>-->
                <!--</span>-->
                <!--</div>-->
                <!--<div class="media-body">-->
                <!--<h6 class="mt-0 mb-1">Your item is shipped</h6>-->
                <!--<div class="font-size-12 text-muted">-->
                <!--<p class="mb-1">If several languages coalesce the grammar</p>-->
                <!--<p class="mb-0">-->
                <!--<i class="mdi mdi-clock-outline"></i> 3 min ago-->
                <!--</p>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</a>-->

                <!--<a href class="text-reset notification-item">-->
                <!--<div class="media">-->
                <!--<img-->
                <!--src="/images/users/avatar-4.jpg"-->
                <!--class="mr-3 rounded-circle avatar-xs"-->
                <!--alt="user-pic"-->
                <!--/>-->
                <!--<div class="media-body">-->
                <!--<h6 class="mt-0 mb-1">Salena Layfield</h6>-->
                <!--<div class="font-size-12 text-muted">-->
                <!--<p class="mb-1">As a skeptical Cambridge friend of mine occidental.</p>-->
                <!--<p class="mb-0">-->
                <!--<i class="mdi mdi-clock-outline"></i> 1 hours ago-->
                <!--</p>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</a>-->
                <!--</simplebar>-->
                <!--<div class="p-2 border-top">-->
                <!--<a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">-->
                <!--<i class="mdi mdi-arrow-down-circle mr-1"></i> Load More..-->
                <!--</a>-->
                <!--</div>-->
                <!--</b-dropdown>-->

                <b-dropdown right variant="black" toggle-class="header-item">
                    <template v-slot:button-content>
                        <span class="d-none d-xl-inline-block ml-1">{{$store.getters.$userName}}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- item-->
                    <router-link class="dropdown-item" to="profile">
                        <i class="bx bx-user font-size-16 align-middle mr-1"></i> Profile
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" @click="logout()">
                        <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout
                    </a>
                </b-dropdown>
            </div>
        </div>
    </header>
</template>

<style scoped>
    :not(.vertical-collpsed) .navbar-brand-box {
        text-align: left;
    }

    .vertical-collpsed .navbar-brand-box {
        text-align: center;
        padding: 0;
    }
</style>