import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/documents",
            name: "Documents",
            component: ()=>import("./views/Documents.vue"),
        },
        {
            path: "/",
            name: "ExternalAgreements",
            component: ()=>import("./views/ExternalAgreements.vue"),
        },
        {
            path: "/internal-agreements",
            name: "InternalAgreements",
            component: ()=>import("./views/InternalAgreements.vue"),
        },
        {
            path: "/supplementary",
            name: "SupplementaryDocs",
            component: ()=>import("./views/SupplementaryDocs.vue"),
        },
        {
            path: "/archive",
            name: "Archive",
            component: ()=>import("./views/Archive.vue"),
        },
        {
            path: "/templates",
            name: "Templates",
            component: ()=>import("./views/Templates.vue"),
        },
        {
            path: '/login',
            name: 'Login',
            component: ()=>import("./views/account/login.vue")
        },
        {
            path: '/users',
            name: 'users',
            component: ()=>import("./views/UserList.vue"),
        },
        {
            path: '/users/create',
            name: 'UserCreate',
            component: () => import('./views/UserCreate.vue')
        },
        {
            path: '/users/edit/:id',
            name: 'UserEdit',
            props: true,
            component: () => import('./views/UserEdit.vue')
        },
        {
            path: '/profile',
            name: 'Profile',
            component: ()=>import("./views/Profile.vue"),
        },
        {
          path: "/parties",
          name: "Parties",
          component: ()=>import("./views/Parties.vue"),
        },
        {
            path: '/example',
            name: 'example',
            component: ()=>import("./views/forms/advanced.vue"),
        },
        { path: '/404', component: ()=>import("./views/utility/404.vue") },
        { path: '*', redirect: '/404' },
    ],
});


router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})


// router.beforeEach((to, from, next) => {
//     // redirect to login page if not logged in and trying to access a restricted page
//     const publicPages = ['/login'];
//     const authRequired = !publicPages.includes(to.path);
//     const isAuth = store.getters.$isAuthed;
//
//     if (authRequired && !isAuth) {
//         return next('/login');
//     }
//
//     next();
// });

export default router