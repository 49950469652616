/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
import router from './route.js';
import store from './store.js';
window.Vue = Vue;

import { BootstrapVue } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import Swal from "sweetalert2";
import NProgress from 'nprogress';

import "nprogress/nprogress.css";

Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;

Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(require("vue-moment"));
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        libraries: "places",
    },
    installComponents: true,
});
Vue.component("apexchart", VueApexCharts);
Vue.component(
    "main-component",
    require("./layouts/main").default
);
Vue.component(
    "dynamic-component",
    require("./components/dynamic-component").default
);

Vue.prototype.apiURL = '/api';


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import "./views";
const app = new Vue({
    el: "#app",
    store,
    router,
    data() {
        return {
            layoutType: "vertical"
        };
    },
    created() {
        const layout = localStorage.getItem("layout-type");
        if (layout) {
            this.layoutType = layout;
        }
    },
    methods: {
        changeMode(mode) {
            let cssUrl = document.getElementById("layout-css").href;
            cssUrl = cssUrl.split("/");
            cssUrl.pop();
            document.cookie="layout="+(mode || "default");
            switch (mode) {
                case "dark":
                    cssUrl.push("app-dark.css");
                    break;
                case "rtl":
                    cssUrl.push("app-rtl.css");
                    break;
                default:
                    cssUrl.push("app.css");
                    break;
            }
            document.getElementById("layout-css").href = cssUrl.join("/");
        },
        changeLayout(layout) {
            this.layoutType = layout;
            localStorage.setItem("layout-type",  layout);
        },
        swalTopRightSuccess(msg, icon="success", timer=3000){
            this.$swal({
                position: "top-end",
                timer: timer,
                title: "Success!",
                html: msg,
                icon: icon,
                showCloseButton: false,
                showConfirmButton: false,
            });
        },
        swalTopRightError(msg, icon="error"){
            this.$swal({
                position: "top-end",
                title: "Error!",
                html: msg,
                icon: icon,
                showCloseButton: false,
                showConfirmButton: true,
            });
        }
    },
    mounted(){
        NProgress.configure({ showSpinner: false });
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("apiKey");
        axios.interceptors.request.use(config => {
            NProgress.start();
            return config;
        }, error => {
            return Promise.reject(error);
        });
        axios.interceptors.response.use((response) => {
            NProgress.done();
            if(response.status === 200 && response.data.status && response.data.status !== 0){
                Swal.fire({
                    position: "top-end",
                    title: "Error!",
                    html: response.data.error,
                    icon: "error",
                    showCloseButton: false,
                    showConfirmButton: true,
                });
                return Promise.reject(response.data);
            }else{
                return response;
            }
        }, (error) => {
            if(error.response.status === 401){
                window.location.href = "/login";
            }else{
                Swal.fire({
                    position: "top-end",
                    title: "Error!",
                    html: error.response.data.message,
                    icon: "error",
                    showCloseButton: false,
                    showConfirmButton: true,
                });
            }
            return Promise.reject(error);
        });
    }
});
